import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import QuarklycommunityKitGallery from "./QuarklycommunityKitGallery";
const defaultProps = {
	"galleryItemNumbProp": "3",
	"columnsCountProp": "3"
};
const overrides = {
	"Item 0": {
		"props": {
			"fullSrc": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/2-4.jpg?v=2024-05-30T08:45:54.425Z",
			"previewSrc": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/2-4.jpg?v=2024-05-30T08:45:54.425Z",
			"fullObjectFit": "contain",
			"previewSrcSet": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/2-4.jpg?v=2024-05-30T08:45:54.425Z",
			"fullSrcSet": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/2-4.jpg?v=2024-05-30T08:45:54.425Z",
			"stretchFull": false
		}
	},
	"Item 1": {
		"props": {
			"fullSrc": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-4.jpg?v=2024-05-30T08:45:54.411Z",
			"previewSrc": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-4.jpg?v=2024-05-30T08:45:54.411Z",
			"previewObjectFit": "contain",
			"stretchFull": false,
			"fullSrcSet": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-4.jpg?v=2024-05-30T08:45:54.411Z"
		}
	},
	"Item 2": {
		"props": {
			"fullSrc": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-2.jpg?v=2024-05-30T08:45:54.410Z",
			"fullObjectFit": "contain",
			"previewSrc": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-2.jpg?v=2024-05-30T08:45:54.410Z",
			"previewObjectFit": "contain",
			"stretchFull": false,
			"fullSrcSet": "https://uploads.quarkly.io/66583b49f0cb1d0025f5b803/images/1-2.jpg?v=2024-05-30T08:45:54.410Z"
		}
	}
};

const Gallery = props => {
	const {
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <QuarklycommunityKitGallery {...rest}>
		<Override
			slot="Item 0"
			fullSrc="https://images.unsplash.com/photo-1616422285623-13ff0162193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
			fullSrcSet="https://images.unsplash.com/photo-1616422285623-13ff0162193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
			fullObjectPosition="contain"
			fullLoading="eager"
			previewSrc="https://images.unsplash.com/photo-1616422285623-13ff0162193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
		/>
		<Override slot="Item 1" fullSrc="https://images.unsplash.com/photo-1706421971606-8d886e104bab?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000" previewSrc="https://images.unsplash.com/photo-1590362891991-f776e747a588?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000" previewObjectFit="contain" />
		<Override slot="Item 2" fullSrc="https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000" previewSrc="https://images.unsplash.com/photo-1580273916550-e323be2ae537?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000" />
		{children}
	</QuarklycommunityKitGallery>;
};

Object.assign(Gallery, { ...QuarklycommunityKitGallery,
	defaultProps,
	overrides
});
export default Gallery;