import React from "react";
import { useOverrides, Override } from "@quarkly/components";
import { Icon, Text, Box, Button, Section, Em } from "@quarkly/widgets";
import { FaPhoneVolume } from "react-icons/fa";
const defaultProps = {
	"quarkly-title": "Schedule-4",
	"padding": "50px 0 60px 0"
};
const overrides = {
	"icon": {
		"kind": "Icon",
		"props": {
			"size": "84px",
			"align-self": "center",
			"margin": "0px 0px 25px 0px",
			"color": "#ab5a24",
			"category": "fa",
			"icon": FaPhoneVolume
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 25px 0px",
			"text-align": "center",
			"font": "normal 500 42px/1.2 --fontFamily-sans",
			"color": "#ab5a24",
			"text-decoration-line": "initial",
			"text-transform": "initial",
			"children": <Em>
				Почніть навчатися сьогодні!
			</Em>
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"text-align": "center",
			"font": "normal 300 16px/1.5 --fontFamily-sansTrebuchet",
			"color": "#707375",
			"width": "700px",
			"align-self": "center",
			"margin": "0 0px 50px 0px",
			"lg-width": "100%",
			"md-margin": "0 0px 35px 0px",
			"children": "Готові змінити своє життя за допомогою мови? Зв’яжіться з Englight English Academy сьогодні та зробіть перший крок до вільного володіння та можливостей. Ваше майбутнє англійською починається тут!"
		}
	},
	"box": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"color": "#707375",
			"margin": "0px 0px 80px 0px",
			"grid-template-columns": "repeat(7, 1fr)",
			"grid-template-rows": "auto",
			"max-width": "100%",
			"display": "grid",
			"lg-grid-template-columns": "repeat(4, 1fr)",
			"lg-grid-template-rows": "auto",
			"md-grid-template-columns": "repeat(3, 1fr)",
			"justify-content": "center",
			"md-margin": "0px 0px 50px 0px",
			"sm-grid-template-columns": "repeat(2, 1fr)"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"lg-display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"font": "italic 300 20px/1.5 --fontFamily-sansTrebuchet",
			"color": "#ab5a24",
			"border-color": "#ab5a24",
			"children": "Понеділок"
		}
	},
	"text3": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-align": "center",
			"font": "normal 300 17px/1.5 --fontFamily-sansTrebuchet",
			"color": "#707375",
			"children": "10:00 - 19:00"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"lg-display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"text4": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"font": "italic 300 20px/1.5 --fontFamily-sansTrebuchet",
			"color": "#ab5a24",
			"border-color": "#ab5a24",
			"children": <>
				Вівторок{" "}
			</>
		}
	},
	"text5": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-align": "center",
			"font": "normal 300 17px/1.5 --fontFamily-sansTrebuchet",
			"color": "#707375",
			"children": "10:00 - 19:00"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"lg-display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"text6": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"font": "italic 300 20px/1.5 --fontFamily-sansTrebuchet",
			"color": "#ab5a24",
			"border-color": "#ab5a24",
			"children": "Середа"
		}
	},
	"text7": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-align": "center",
			"font": "normal 300 17px/1.5 --fontFamily-sansTrebuchet",
			"color": "#707375",
			"children": "10:00 - 19:00"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"lg-display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"text8": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"font": "italic 300 20px/1.5 --fontFamily-sansTrebuchet",
			"color": "#ab5a24",
			"border-color": "#ab5a24",
			"children": "Четвер"
		}
	},
	"text9": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-align": "center",
			"font": "normal 300 17px/1.5 --fontFamily-sansTrebuchet",
			"color": "#707375",
			"children": "10:00 - 19:00"
		}
	},
	"box5": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"lg-display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"text10": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"font": "italic 300 20px/1.5 --fontFamily-sansTrebuchet",
			"color": "#ab5a24",
			"border-color": "#ab5a24",
			"children": "П`ятниця"
		}
	},
	"text11": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-align": "center",
			"font": "normal 300 17px/1.5 --fontFamily-sansTrebuchet",
			"color": "#707375",
			"children": "10:00 - 19:00"
		}
	},
	"box6": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"lg-display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"text12": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"font": "italic 300 20px/1.5 --fontFamily-sansTrebuchet",
			"color": "#ab5a24",
			"border-color": "#ab5a24",
			"children": "Субота"
		}
	},
	"text13": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-align": "center",
			"font": "normal 300 17px/1.5 --fontFamily-sansTrebuchet",
			"color": "#707375",
			"children": "10:00 - 19:00"
		}
	},
	"box7": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"margin": "0px 0 0px 0px",
			"lg-display": "flex",
			"lg-flex-direction": "column"
		}
	},
	"text14": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 15px 0px",
			"text-align": "center",
			"font": "italic 300 20px/1.5 --fontFamily-sansTrebuchet",
			"color": "#ab5a24",
			"border-color": "#ab5a24",
			"children": "Неділя"
		}
	},
	"text15": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"text-align": "center",
			"font": "normal 300 17px/1.5 --fontFamily-sansTrebuchet",
			"color": "#707375",
			"children": "10:00 - 19:00"
		}
	},
	"box8": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"display": "flex",
			"sm-flex-direction": "column"
		}
	},
	"box9": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-width": "2px",
			"border-style": "solid",
			"border-color": "--color-red",
			"padding": "40px 50px 40px 50px",
			"sm-width": "100%",
			"md-padding": "20px 20px 20px 20px",
			"justify-content": "center",
			"align-content": "center"
		}
	},
	"text16": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"text-align": "center",
			"font": "--headline3",
			"color": "--greyD2",
			"children": "0322600416"
		}
	},
	"text17": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 30px 0px",
			"text-align": "center",
			"font": "--headline3",
			"color": "--greyD2",
			"children": "support@glintarastore.com"
		}
	},
	"button": {
		"kind": "Button",
		"props": {
			"padding": "13px 24px 13px 24px",
			"background": "--color-red",
			"font": "normal 500 16px/1.5 --fontFamily-sansTrebuchet",
			"type": "link",
			"href": "tel:+903842713016",
			"display": "none",
			"children": "Bize Ulaşın"
		}
	},
	"box10": {
		"kind": "Box",
		"props": {
			"min-width": "100px",
			"min-height": "100px",
			"width": "50%",
			"display": "flex",
			"flex-direction": "column",
			"align-items": "center",
			"border-width": "2px 2px 2px 0",
			"border-style": "solid",
			"border-color": "--color-red",
			"padding": "40px 50px 40px 50px",
			"sm-width": "100%",
			"md-padding": "20px 20px 20px 20px",
			"sm-border-width": "0 2px 2px 2px",
			"justify-content": "center"
		}
	},
	"text18": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 10px 0px",
			"text-align": "center",
			"font": "normal 500 28px/1.2 --fontFamily-sans",
			"color": "--greyD2",
			"children": <>
				Kyryla i Mefodiya St, 17А,
				<br />
				{" "}Lviv, Lviv Oblast, 79000
			</>
		}
	}
};

const Contacts = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override slot="SectionContent" border-color="#ab5a24" color="#ab5a24" />
		<Icon {...override("icon")} />
		<Text {...override("text")} />
		<Text {...override("text1")} />
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Text {...override("text2")} />
				<Text {...override("text3")} />
			</Box>
			<Box {...override("box2")}>
				<Text {...override("text4")} />
				<Text {...override("text5")} />
			</Box>
			<Box {...override("box3")}>
				<Text {...override("text6")} />
				<Text {...override("text7")} />
			</Box>
			<Box {...override("box4")}>
				<Text {...override("text8")} />
				<Text {...override("text9")} />
			</Box>
			<Box {...override("box5")}>
				<Text {...override("text10")} />
				<Text {...override("text11")} />
			</Box>
			<Box {...override("box6")}>
				<Text {...override("text12")} />
				<Text {...override("text13")} />
			</Box>
			<Box {...override("box7")}>
				<Text {...override("text14")} />
				<Text {...override("text15")} />
			</Box>
		</Box>
		<Box {...override("box8")}>
			<Box {...override("box9")}>
				<Text {...override("text16")} />
				<Text {...override("text17")} />
				<Button {...override("button")}>
					Зв'язатися
				</Button>
			</Box>
			<Box {...override("box10")}>
				<Text {...override("text18")} />
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Contacts, { ...Section,
	defaultProps,
	overrides
});
export default Contacts;